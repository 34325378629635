<template>
  <v-content color="primary">
    <v-container fluid id="main-container" grid-list-md>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        top
      >
        {{ t(text) }}
        <v-btn
          flat
          @click="snackbar = false"
        >
          {{t('Close')}}
        </v-btn>
      </v-snackbar>
      <v-layout row wrap justify-center class="d-inline-block w-100 mb-5">
        <v-card>
          <v-card-title>
            <span class="menu-icon bigger material-symbols-outlined"> groups </span>
            <span class="table-title">{{ t('Subscribers') }}</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="t('Search')"
              single-line
              hide-details
            ></v-text-field>
           </v-card-title>
           <v-data-table
              :headers="headers"
              :items="subscribers"
              class="elevation-1"
              :loading="loading"
              :search="search"
              :pagination.sync="pagination"
              :total-items="10"
              hide-actions
           >
            <template v-slot:items="props">
              <td class="text-xs-left no-wrap">
                {{ props.item.last_name + " " + props.item.first_name }}
              </td>
              <td class="text-xs-left no-wrap">{{ props.item.email }}</td>
              <td v-if="!$store.getters.isPREPAYMENT" class="text-xs-left no-wrap">{{ props.item.balance + currency }}</td>
              <td class="text-xs-left no-wrap">
                <v-flex
                  style="display: flex; padding: 0; margin: 0; height: 100%"
                >
                  <div
                    style="margin-right: 10px; padding-top: 15px;"
                  >
                    {{ props.item.cards.length }}
                  </div>
                  <v-list-group
                    style="display: inline; padding-top: 14px; margin-bottom: 10px; margin-left: 10px"
                  >
                    <div
                      v-for="card in props.item.cards"
                      :key="card.pk"
                    >
                      <a :href="`/cards/${card.pk}/details/`" class="url-text">
                        {{ card.pk }}
                      </a>
                    </div>
                  </v-list-group>
                </v-flex>
              </td>
              <td class="text-xs-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn 
                      v-on="on"
                      :to="`/subscribers/${props.item.pk}/details/`"
                      color="info"
                      small icon ripple dark
                    >
                      <v-icon small>info</v-icon>
                    </v-btn>
                  </template>
                  <span>{{t('Detail')}}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="subscriberEditPermission">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="`/subscribers/${props.item.pk}/edit/`" color="success" icon ripple small dark>
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{t('Edit')}}</span>
                </v-tooltip>
              </td>
            </template>
            <template slot="pageText" slot-scope="item">
              {{t('total')}}: {{count}}
            </template>
            <template slot="no-data">
              <v-subheader>{{t('No data available')}}</v-subheader>
            </template>
          </v-data-table>
          <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap;">
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              :total-visible="5"
            ></v-pagination>
            <v-select style="margin-left: 10px; max-width: 50px" :items="[10, 25, 50]" v-model="pagination.rowsPerPage">
            </v-select>
          </div>
        </v-card>
      </v-layout>
      <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn color="primary"
              dark
              fixed
              bottom
              right
              icon
              large
              v-on="on"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{t('User Profile')}}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form">
                <v-layout wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      :label="t('First name*')" v-model="firstName"
                      :rules="[rules.required,]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      :label="t('Last name*')"
                      v-model="lastName"
                      :rules="[rules.required,]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :label="t('Email')"
                      v-model="email"
                      :rules="[rules.email]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      ref="address"
                      v-model="address"
                      :rules="[rules.required,
                      () => !!address && address.length <= 50 || 'Address must be less than 50 characters',
                      addressCheck]"
                      :label="t('Address*')"
                      :placeholder="t('Address*')"
                      counter="50"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field :label="t('Phone*')"
                      v-model="phone"
                      :rules="[rules.required,]"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>{{t('*indicates required field')}}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">{{t('Close')}}</v-btn>
            <v-btn color="primary" text @click="submitSubscriberForm">{{t('Save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-content>
</template>

<script>
  import { debounce } from 'vue-debounce';
  import axios from 'axios';
  export default {
    name: "Subscribers",
    data() {
      return {
        pagination: {
          current: 1,
          total: 20,
          rowsPerPage: 10,
          descending: false,
          sortBy: 'id'
        },
        dialog: false,
        next: null,
        previous: null,
        count: 0,
        headers: [
          {
            text: this.$translate.locale['Full name'],
            align: 'left',
            value: 'first_name',
          },
          {text: this.$translate.locale['Email'], value: 'email'},
          {text: this.$translate.locale['Balance'], value: 'balance'},
          {text: this.$translate.locale['Cards'], value: 'cards'},
          {text: this.$translate.locale['Action'], value: 'action', sortable: false},
        ],
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        subscribers: [],
        loading: true,
        snackbar: false,
        search: '',
        text: 'Oops... Something went wrong',
        timeout: 5000,
        rules: {
          required: value => !!value || this.$translate.locale['Required.'],
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !value || pattern.test(value) || this.$translate.locale['Invalid e-mail.']
          },
        },
        subscriberEditPermission: false,

        getDataDebounced: debounce((...args) => this.getData(...args), '300ms'),

        axiosGetDataCancelToken: axios.CancelToken.source(),
      }
    },
    methods: {
      getData(resetPage=false) {
        // cancelling previos getData request
        this.axiosGetDataCancelToken.cancel();
        // recreating request cancel token
        this.axiosGetDataCancelToken = axios.CancelToken.source();

        this.loading = true;
        
        let url = (
          `/api/subscribers/?`
           + `page_size=${this.pagination.rowsPerPage}`
           + `&page=${this.pagination.current}`
           + `&sort_by=` + (this.pagination.descending ? `-${this.pagination.sortBy}` : this.pagination.sortBy)
        );
        
        if (this.search) {
          url += `&name=${this.search}`;

          if (this.pagination.current !== 1 && resetPage) {
            this.pagination.current = 1;
            // return, because changing this.pagination.current will 
            // trigger another call of getData()
            return;
          }
        }

        axios.get(url, {cancelToken: this.axiosGetDataCancelToken.token})
          .then((response) => {
            if (response.status === 200) {
              this.subscribers = response.data.results;
              this.previous = response.data.previous;
              this.next = response.data.next;
              this.count = response.data.count;
              this.pagination.total = response.data.total_pages;
              this.loading = false;
            }
          }).catch((error) => {
            if (axios.isCancel(error)) return;
            
            if (error.response.status === 404) {
              this.text = 'Page not found';
              this.snackbar = true;
              this.loading = false;
            }
          });
      },
      addressCheck() {
        this.errorMessages = this.address && !this.name
          ? 'Hey! I\'m required' : '';
        return true
      },
      submitSubscriberForm() {
        if (!this.$refs.form.validate()) {
          this.text = "Fill the form correctly";
          this.snackbar = true
        } else {
          const data = {
            crossdomain: true,
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            telephone: this.phone,
            address: this.address
          };
          axios.post(`/api/subscribers/create/`, data)
            .then((response) => {
              if (response.status === 201) {
                this.submitted = true;
                this.dialog = false;
                this.getData();
                this.firstName = '';
                this.lastName = '';
                this.email = '';
                this.phone = '';
                this.address = '';
                this.text = "Subscriber created!";
                this.snackbar = true;
              }
            }).catch((error) => {
              this.text = "Connection error";
              this.snackbar = true;
            });
        }
      },
      getSubscriberEditPermission() {
        axios.get('/api/subscribers/edit_permission/')
          .then(response => {
            if (response.status === 200) {
              this.subscriberEditPermission = response.data.has_permission
            }
          });
      }
    },
    beforeCreate() {
      if (!this.$store.getters.loggedIn) {
        this.$router.push('/');
      }
    },
    beforeMount(){
      this.getSubscriberEditPermission();
      if(this.$store.getters.isPREPAYMENT){
        this.headers= [
          {
            text: this.$translate.locale['Full name'],
            align: 'left',
            value: 'last_name',
          },
          {text: this.$translate.locale['Email'], value: 'email'},
          {text: this.$translate.locale['Cards'], value: 'cards'},
          {text: this.$translate.locale['Action'], value: 'action', sortable: false},
        ]
      }
    },
    mounted() {
      this.getData();
      this.currency = this.$store.getters.currency;
    },
    watch: {
      'pagination.current': function() { this.getDataDebounced(); },
      'pagination.rowsPerPage': function (newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
      'pagination.descending': function () { this.getDataDebounced(); },
      'pagination.sortBy': function () { this.getDataDebounced(); },
      'search': function (newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
    }
  }
</script>

<style scoped>
  .url-text {
    color: #007bff;
    text-decoration: none;
  }
</style>
